import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/layout/default/container';
import { truncateString } from '../utils/helpers';
import SEO from '../components/layout/seo';


function LeaguePreview({ league }) {
  const { image, banner_image: banner, name, description, short_description: shortDescription, slug } = league;
  const url = `/league/${slug}`;
  return (
    // <div className="max-w-2xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:max-w-7xl"></div>
    <div className="max-w-2xl mx-auto py-8 px-4 sm:py-12 sm:px-6">
      <div className="relative rounded-lg overflow-hidden lg:h-96">
        <div className="absolute inset-0">
          <img
            src={banner || image}
            alt={name}
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div aria-hidden="true" className="relative w-full h-96 lg:hidden" />
        <div aria-hidden="true" className="relative w-full h-32 lg:hidden" />
        {/* <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-75 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between lg:inset-y-0 lg:inset-x-auto lg:w-96 lg:rounded-tl-lg lg:rounded-br-none lg:flex-col lg:items-start"> */}
        <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-75 p-6 rounded-bl-lg rounded-br-lg backdrop-filter backdrop-blur sm:flex sm:items-center sm:justify-between">
          <div>
            <h2 className="text-xl font-bold text-white">{name}</h2>
            <p className="mt-1 text-sm text-gray-300">
              {truncateString(shortDescription || description, 320)}
            </p>
          </div>
          <Link
            to={url}
            className="mt-6 flex-shrink-0 flex bg-white bg-opacity-0 py-3 px-4 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8"
            // className="mt-6 flex-shrink-0 flex bg-white bg-opacity-0 py-3 px-4 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white hover:bg-opacity-10 sm:mt-0 sm:ml-8 lg:ml-0 lg:w-full"
          >
            View this league
          </Link>
        </div>
      </div>
    </div>
  );
}

export default function Leagues({ pageContext }) {
  const { leagues } = pageContext;

  return (
    <Container>
      <SEO title="Browse leagues" />
      <div className="bg-white">
        <div className="max-w-xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Browse Leagues</h2>
          <p className="mt-4 text-base text-gray-500">
            Find bowling centers near you based on the type of league that you are interested in.
          </p>
        </div>
        {leagues.map((league) => <LeaguePreview key={league.id} league={league} />)}
      </div>
    </Container>
  );
}
